/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react/jsx-no-bind */
import React from "react";
import { cache } from "swr";
import { useLocation, useNavigate } from "react-router-dom";
import {
    HeaderWrapper,
    MenuBurgerIcon,
    StyledIconButton,
    StyledH1,
} from "@aureskonnect/react-ui";
import classNames from "classnames";

import { RootState } from "@store/.";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Tooltip } from "@mui/material";
// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode";
import { useKeycloak } from "@react-keycloak/web";

import LanguageDropdown from "@components/CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "@components/CommonForBoth/TopbarDropdown/ProfileMenu";

import { HomeSvgIcon, ShopIconHeader } from "../Common/SvgIcons";

import "./layout.css";
import "./index.css";

type HeaderPropsType = {
    handleSideBarCollapse: any;
    brand: string;
    showBrand: boolean;
    // eslint-disable-next-line
    customCss?: React.CSSProperties;
};
type BrandPropsType = {
    brand: string;
};
function Brand({ brand }: BrandPropsType) {
    const mainColor: string = useSelector(
        (State: RootState): string => State.colorSlice.dominantColor
    );

    const selectedAccountId = localStorage.getItem("customerAccountId");

    return (
        <div
            style={{
                cursor: selectedAccountId !== "" ? "pointer" : "unset",
                width: "274px",
            }}
            role="button"
            tabIndex={0}
        >
            <div
                style={{ backgroundColor: mainColor }}
                className="navbar-brand-box d-none d-sm-flex"
            >
                <StyledH1 className="text-white d-flex justify-content-center brand__clz mb-0 p-2">
                    {brand}
                </StyledH1>
            </div>
            <div className="navbar-brand-box d-block d-sm-none">
                <StyledH1 className="text-white d-flex justify-content-center brand__clz mb-0 p-2">
                    {brand.slice(0, 2)}
                </StyledH1>
            </div>
        </div>
    );
}

export default function Header({
    handleSideBarCollapse,
    brand,
    showBrand,
}: HeaderPropsType): JSX.Element {
    const { t } = useTranslation();
    const collapsed = useSelector(
        (state: RootState) => state.layoutSlice.collapsed
    );
    const location = useLocation();
    const navigate = useNavigate();
    const isTablet = useSelector(
        (state: RootState): boolean => state.layoutSlice.isTablet
    );
    const NumberOfAccount = localStorage.getItem("NumberOfAccount");
    const [menu, setMenu] = React.useState<boolean>(false);
    const [name, setName] = React.useState<string>("");
    const [userFirstName, setUserFirstName] = React.useState<any>("");
    const [userLastName, setUserLastName] = React.useState<any>("");
    const tokenUserTmp: any = localStorage.getItem("access_token_id");
    const email = localStorage.getItem("email");
    const { keycloak } = useKeycloak();

    function logout() {
        cache.clear();
        localStorage.removeItem("Uid_Entitie_User");
        localStorage.clear();
        // navigate("/");

        keycloak.logout();
    }

    const cryptDecryptDataUser = React.useCallback(
        async (wordToDecrypt: string, stateSetter: typeof Function) => {
            // eslint-disable-next-line no-return-await
            return await fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_API_URL}/decryptWord`,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        authorization: `Bearer ${keycloak.token}`,
                    },
                    body: `wordToDecrypted=${wordToDecrypt}`,
                    method: "POST",
                }
            )
                .then((result) => result.text())
                .then((response: any) => {
                    stateSetter(response);
                    return response;
                })
                .catch(() => {
                    stateSetter("");
                    return "";
                });
        },
        [keycloak.token]
    );

    React.useEffect(() => {
        if (tokenUserTmp != null) {
            const dataUserConnected: any = jwt_decode(tokenUserTmp);

            setUserFirstName(async () => {
                // eslint-disable-next-line no-return-await
                return await cryptDecryptDataUser(
                    dataUserConnected.sub.firstname,
                    setUserFirstName as typeof Function
                );
            });
            setUserLastName(async () => {
                // eslint-disable-next-line no-return-await
                return await cryptDecryptDataUser(
                    dataUserConnected.sub.name,
                    setUserLastName as typeof Function
                );
            });
        } else {
            setUserFirstName(async () => {
                const fisrtNameLocalStorage: any =
                    localStorage.getItem("firstName");
                // eslint-disable-next-line no-return-await
                return await cryptDecryptDataUser(
                    fisrtNameLocalStorage,
                    setUserFirstName as typeof Function
                );
            });
            setUserLastName(async () => {
                const lastNameLocalStorage: any =
                    localStorage.getItem("lastName");
                // eslint-disable-next-line no-return-await
                return await cryptDecryptDataUser(
                    lastNameLocalStorage,
                    setUserLastName as typeof Function
                );
            });
        }
    }, [tokenUserTmp, cryptDecryptDataUser]);

    React.useEffect(() => {
        if (userFirstName !== "" && userLastName !== "") {
            const fullName = `${userFirstName} ${userLastName}`;
            setName(fullName);
            localStorage.setItem("Name", `${userLastName} ${userFirstName}`);
            // eslint-disable-next-line sonarjs/no-collapsible-if
        } else if (localStorage.getItem("authUser")) {
            if (
                process.env.REACT_APP_DEFAULTAUTH === "fake" ||
                process.env.REACT_APP_DEFAULTAUTH === "jwt"
            ) {
                const obj = JSON.parse(localStorage.getItem("authUser")!);
                setName(
                    `${
                        obj.firstname
                            ? `${obj.firstname} ${obj.lastname}`
                            : obj.username
                    }`
                );
                localStorage.setItem(
                    "Name",
                    `${
                        obj.firstname
                            ? `${obj.lastname} ${obj.firstname}`
                            : obj.username
                    }`
                );
            }
        }
    }, [userFirstName, userLastName]);

    return (
        <HeaderWrapper
            style={{
                gridArea: "header",
                backgroundColor: "#ffffff",
            }}
        >
            <div className="navbar-header">
                <div className="d-flex align-items-center">
                    {(location.pathname !== "/customer-accounts" &&
                        showBrand &&
                        !isTablet) ||
                    location.pathname === "/customer-accounts" ? (
                        <Brand brand={brand} />
                    ) : null}

                    {location.pathname !== "/customer-accounts" && (
                        <div
                            className={classNames(
                                "d-flex justify-content-center align-items-center",
                                {
                                    "ml-2": collapsed === true,
                                    "ml-3": collapsed === false,
                                    "d-none": "/customer-accounts",
                                }
                            )}
                            style={{ columnGap: 10, cursor: "pointer" }}
                        >
                            <StyledIconButton
                                className="m-0 border-none__clz sid_btn_openClose"
                                icon="CloudIcon"
                            >
                                <MenuBurgerIcon
                                    height={20}
                                    width={20}
                                    onClick={handleSideBarCollapse}
                                />
                            </StyledIconButton>
                        </div>
                    )}
                </div>

                <div
                    className="d-flex align-items-center"
                    style={{ gap: "10px" }}
                >
                    <LanguageDropdown />

                    <Tooltip title="">
                        <div>
                            <StyledIconButton
                                className="m-2 border-none__clz"
                                icon="ShopIcon"
                                onClick={() => {
                                    window.location.replace(
                                        `${process.env.REACT_APP_URL_HOME}`
                                    );
                                }}
                            >
                                <HomeSvgIcon />
                            </StyledIconButton>
                        </div>
                    </Tooltip>

                    <Tooltip
                        title={t("Multi-store display").toString()}
                        className={
                            (NumberOfAccount &&
                                Number(NumberOfAccount) === 1) ||
                            (NumberOfAccount && Number(NumberOfAccount) === 0)
                                ? "disabled_clz"
                                : ""
                        }
                    >
                        <div
                            onClick={() => {
                                navigate("/customer-accounts");
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            <ShopIconHeader height={25} width={25} />
                        </div>
                    </Tooltip>
                    <ProfileMenu
                        // eslint-disa  no-unused-vars
                        logout={logout}
                        menu={menu}
                        setMenu={setMenu}
                        name={name}
                        email={email}
                    />
                </div>
            </div>
        </HeaderWrapper>
    );
}
