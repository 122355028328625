import React from "react";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";

import { Card } from "reactstrap";
import { DynamicTable } from "@aures/custom-react-table";
import { PageTitle, Breadcurmb } from "@components/index";

import SelectedCustomerAccountButton from "./SelectedCustomerAccountButton";
import AccountIdentifier from "./AccountIdentifier";

import "./index.css";

export default function CustomerAccountListWrapper(): JSX.Element {
    const { t, i18n } = useTranslation();
    const { keycloak } = useKeycloak();

    const uidUserEntity = localStorage.getItem("Uid_Entitie_User");
    const uidUser = localStorage.getItem("Uid_User");

    const NumberOfAccount = localStorage.getItem("NumberOfAccount");

    // eslint-disable-next-line no-unused-vars
    const [, setData] = React.useState<any>({});
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const arrayOfCustomColumns = [
        {
            indexOFColumn: 8,
            columnName: t("Identifiant"),
            customJsx: AccountIdentifier,
        },
        {
            indexOFColumn: 99,
            columnName: "Actions",
            customJsx: SelectedCustomerAccountButton,
        },
    ];
    const isVisible = true;

    React.useEffect(() => {
        setDataIsUpdated(true);
    }, [i18n.language]);

    return (
        <React.Fragment>
            <PageTitle title="Access" />
            {Number(NumberOfAccount) !== 0 ? (
                <div className="page-content" style={{ width: "100%" }}>
                    <Breadcurmb title={t("Please choose a customer account")} />
                    <Card
                        style={{
                            padding: "20px",
                            margin: "9px",
                            height: "654px !important",
                        }}
                    >
                        <DynamicTable
                            requestHeader={{
                                "Accept-Language": i18n.language,
                                authorization: `Bearer ${keycloak.token}`,
                            }}
                            url={`${process.env.REACT_APP_CONSOLE_ADMIN_API_URL}/accounts/${uidUserEntity}/${uidUser}`}
                            actionColumn={() => null}
                            arrayOfCustomColumns={arrayOfCustomColumns}
                            setData={setData}
                            setDataIsUpdated={setDataIsUpdated}
                            dataIsUpdated={dataIsUpdated}
                            canResize
                            showGlobalFilter
                            showFilter
                            setLocalFilterActive={setLocalFilterActive}
                            filterActive={filterActive}
                            minHeight={`calc(100vh - (370px + ${
                                isVisible ? "100px" : "0px"
                            }))`}
                            maxHeight={`calc(100vh - (370px + ${
                                isVisible ? "125px" : "0px"
                            }))`}
                        />
                    </Card>
                </div>
            ) : (
                <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{ marginTop: "238px" }}
                >
                    <img
                        alt="img"
                        src="./images/emptyAccounts.png"
                        style={{
                            width: "248px",
                            height: "248px",
                        }}
                    />
                    <span
                        style={{
                            width: "295px",
                            height: "40px",
                            textAlign: "left",
                            font: "normal normal 600 30px/40px Segoe UI",
                            color: "#495057",
                        }}
                    >
                        {t("No account found")}
                    </span>
                </div>
            )}
        </React.Fragment>
    );
}
