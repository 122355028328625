import React from "react";

import { useTranslation } from "react-i18next";
import { StyledButton, CheckboxIcon } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { mutate } from "swr";

import { DynamicTableWrapperContext, DominantColor } from "@components/Common/";

import { toast } from "react-toastify";
import { StatusActive } from "@constants/index";
import { useKeycloak } from "@react-keycloak/web";

type ConfirmModalPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
};

export default function ConfirmUnArchivedModal({
    isOpen,
    setIsOpen,
}: ConfirmModalPropsType): JSX.Element {
    const { keycloak } = useKeycloak();
    const { t } = useTranslation();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const customerAccountId = localStorage.getItem("customerAccountId");
    const responsable = localStorage.getItem("Name");
    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);
    const dominantColor: string = DominantColor();
    const userData = dynamicTableWrapperContext?.rowData?.original;
    let dataCustomerAccount: string = "";

    async function handleUnArchivedUserAccountOnClickEvent(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        // Prevent Multiple Button Clicks
        e.currentTarget.disabled = true;

        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/update-user-status`;
        if (dynamicTableWrapperContext?.rowData?.original) {
            dataCustomerAccount += `'${dynamicTableWrapperContext?.rowData?.original.ID}'`;
        } else {
            Object.values(dynamicTableWrapperContext?.rowData).forEach(
                (userItem: any, index: number) => {
                    if (index !== 0) {
                        dataCustomerAccount += `, '${userItem.ID}'`;
                    } else {
                        dataCustomerAccount += `'${userItem.ID}'`;
                    }
                }
            );
        }
        if (validateOnclick) {
            setValidateOnclick(false);
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${keycloak.token}`,
                        },
                        body: JSON.stringify({
                            userAccountStatus: StatusActive,
                            customerAccountUUid: dataCustomerAccount,
                        }),
                        method: "PUT",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error(
                                    `${t(
                                        "Error while unarchiving user account"
                                    )}!`
                                );
                            }
                            handleSavedUnArchivedHistoryOnClickEvent();
                            toast.success(
                                dynamicTableWrapperContext?.rowData?.original
                                    ? `${t(
                                          "The user account has been unarchived successfully"
                                      )!}`
                                    : `${t(
                                          "The user accounts have been unarchived successfully"
                                      )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );

                            dynamicTableWrapperContext?.setDataUpdated(true);
                            dynamicTableWrapperContext?.setActionColumnDisplay(
                                false
                            );
                            setValidateOnclick(true);
                            setIsOpen(false);
                        })
                );
            } catch (e: any) {
                toast.error(`${t("Error while unarchiving user account")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    async function handleSavedUnArchivedHistoryOnClickEvent() {
        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/history`;
        let data: any = [];
        if (dynamicTableWrapperContext?.rowData?.original) {
            data = [
                {
                    typeHistory: 7,

                    oldData: {
                        Nom: `${dynamicTableWrapperContext?.rowData?.original.Nom}`,
                        Prénom: `${dynamicTableWrapperContext?.rowData?.original.Prénom}`,
                        Status: dynamicTableWrapperContext?.rowData?.original
                            .status,
                    },
                    newData: {
                        Nom: `${dynamicTableWrapperContext?.rowData?.original.Nom}`,
                        Prénom: `${dynamicTableWrapperContext?.rowData?.original.Prénom}`,
                        Status: StatusActive,
                    },
                    operator: responsable,
                    uidUpdatedData:
                        dynamicTableWrapperContext?.rowData?.original?.ID,
                },
            ];
        } else {
            Object.values(dynamicTableWrapperContext?.rowData).forEach(
                (userItem: any) => {
                    const data1 = {
                        typeHistory: 7,

                        oldData: {
                            Nom: `${userItem.Nom}`,
                            Prénom: `${userItem.Prénom}`,
                            Status: userItem.status,
                        },
                        newData: {
                            Nom: `${userItem.Nom}`,
                            Prénom: `${userItem.Prénom}`,
                            Status: StatusActive,
                        },
                        operator: responsable,
                        uidUpdatedData: userItem?.ID,
                    };
                    data.push(data1);
                }
            );
        }

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${keycloak.token}`,
                    },
                    body: JSON.stringify({
                        data,
                        customerAccountUUid: customerAccountId,
                    }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error(
                                `${t("Error while archiving user account")}!`
                            );
                        }
                    })
            );
        } catch (e: any) {
            toast.error(`${t("Error while archiving user account")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <Modal centered isOpen={isOpen}>
            <ModalHeader toggle={(): boolean => setIsOpen(!isOpen)}>
                <span className="text-uppercase cmn_txt_titleAlert">
                    {t("Confirmation")}
                    {dynamicTableWrapperContext?.rowData?.title}
                </span>
            </ModalHeader>
            <ModalBody className="cmn_txt_descriptionAlert">
                {userData
                    ? `${t(
                          "Do you want to confirm the unarchiving of the user account"
                      )} ${userData?.Nom}  ${userData?.Prénom}`
                    : t(
                          "Do you want to confirm the unarchiving of the selected user accounts ?"
                      )}
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    className="cmn_btn_cancelAlert"
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>

                <StyledButton
                    className="cmn_btn_validateAlert"
                    onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleUnArchivedUserAccountOnClickEvent(e);
                    }}
                    rounded
                    variant={dominantColor}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
