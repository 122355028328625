/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import { useKeycloak } from "@react-keycloak/web";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";

import { DominantColor } from "@components/Common";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";
import {
    PROFILE_ACCESS_ATHORIZATION_ID,
    USER_ACCOUNT_ACCESS_ATHORIZATION_ID,
    USER_ARCHIVED_ACCESS_ATHORIZATION_ID,
    USER_BLOCKED_ACCESS_ATHORIZATION_ID,
} from "@constants/index";

export default function SelectedCustomerAccountButton({ selectedRow }: any) {
    const { keycloak } = useKeycloak();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dominantColor: string = DominantColor();

    const authorization = useSelector(
        (state: RootState) => state.ProfileAuthorizationSlice.data
    );

    return (
        <StyledButton
            outline
            rounded
            variant={dominantColor}
            onClick={async (event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                localStorage.setItem("customerAccountId", selectedRow.uid);

                localStorage.setItem(
                    "TypeAccount",
                    selectedRow[t("Account type")]
                );

                localStorage.setItem(
                    "customerAccountName",
                    selectedRow[t("Customer account")]
                );

                const selectedCustomerCodeUrl = await fetch(
                    `${process.env.REACT_APP_SETTING_API_URL}settings/pays`,
                    {
                        headers: {
                            authorization: `Bearer ${keycloak.token}`,
                        },
                    }
                );

                const countryCode: any = await selectedCustomerCodeUrl.json();

                if (countryCode.data !== undefined) {
                    const selectedCountryCode = countryCode.data.find(
                        (data: any) =>
                            data.Designation === selectedRow[t("Country")]
                    );

                    localStorage.setItem(
                        "selectedCountryCode",
                        selectedCountryCode.code.toLowerCase()
                    );
                } else {
                    localStorage.setItem("selectedCountryCode", "fr");
                }
                // Will check visibility authorization of user profile
                // will fall back to user accounts if have no visibility
                if (
                    authorization[USER_ACCOUNT_ACCESS_ATHORIZATION_ID] !==
                        undefined &&
                    authorization[USER_ACCOUNT_ACCESS_ATHORIZATION_ID].Consult
                ) {
                    navigate("/users-accounts");
                } else if (
                    authorization[USER_BLOCKED_ACCESS_ATHORIZATION_ID] !==
                        undefined &&
                    authorization[USER_BLOCKED_ACCESS_ATHORIZATION_ID].Consult
                ) {
                    navigate("/blocked-accounts");
                } else if (
                    authorization[USER_ARCHIVED_ACCESS_ATHORIZATION_ID] !==
                        undefined &&
                    authorization[USER_ARCHIVED_ACCESS_ATHORIZATION_ID].Consult
                ) {
                    navigate("/archived-accounts");
                } else if (
                    authorization[PROFILE_ACCESS_ATHORIZATION_ID] !==
                        undefined &&
                    authorization[PROFILE_ACCESS_ATHORIZATION_ID].Consult
                ) {
                    navigate("/profiles");
                } else {
                    navigate("/users-accounts");
                }
            }}
            style={{ minHeight: "20px", maxHeight: "37px", minWidth: "136px" }}
        >
            {t("To access")}
        </StyledButton>
    );
}
