import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { mutate } from "swr";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { StyledButton, CheckIcon } from "@aureskonnect/react-ui";
import { useKeycloak } from "@react-keycloak/web";

import { RootState } from "@store/index";

import { toast } from "react-toastify";

import { DominantColor, DynamicTableWrapperContext } from "@components/Common";

import { ManageAccessModalContent } from "./ManageAccessModalContent";
import ConfirmAccessModification from "./ConfirmAccessModification";

import "../index.css";

type ManageAccessModalPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
    handleSaveUserButtonOnClickEvent?: Function;
    user?: any;
    selectedRows?: any[];
};

export function ManageAccessModal({
    isOpen,
    setIsOpen,
    handleSaveUserButtonOnClickEvent,
    user,
    selectedRows,
}: ManageAccessModalPropsType) {
    const { keycloak } = useKeycloak();
    const { t } = useTranslation();
    const customerAccountId = localStorage.getItem("customerAccountId");
    const responsable = localStorage.getItem("Name");
    const access = useSelector((state: RootState) => state.accessSlice.access);

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const dominantColor: string = DominantColor();

    const [isConfirmAccessModificationOpened, setIsConfirmAccessModification] =
        React.useState(false);
    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);

    const userData = dynamicTableWrapperContext?.rowData?.original;

    const userInfo = {
        ...user,
        access_authorization: [...access],
    };

    // eslint-disable-next-line sonarjs/cognitive-complexity
    async function handleManageAcesstOnClickEvent() {
        if (
            userData === undefined &&
            (selectedRows?.length === 0 || selectedRows === undefined)
        ) {
            handleSaveUserButtonOnClickEvent !== undefined &&
                handleSaveUserButtonOnClickEvent(userInfo);
        } else {
            let userAccountUUids = [];

            if (userData?.ID !== undefined && selectedRows?.length === 0) {
                userAccountUUids = [userData?.ID];
            } else {
                userAccountUUids = selectedRows!.map((row) => {
                    return row.ID;
                });
            }

            const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/update-user-access`;
            if (validateOnclick) {
                setValidateOnclick(false);
                try {
                    mutate(
                        apiUrl,
                        await fetch(apiUrl, {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bearer ${keycloak.token}`,
                            },
                            body: JSON.stringify({
                                access_authorization: access,
                                userAccountUUids,
                            }),
                            method: "PUT",
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.error) {
                                    throw Error();
                                }

                                HandleSaveUserAccessHistory();

                                toast.success(
                                    `${t("Changes made successfully")!}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        icon: (
                                            <CheckIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );

                                dynamicTableWrapperContext?.setDataUpdated(
                                    true
                                );
                                dynamicTableWrapperContext?.setActionColumnDisplay(
                                    false
                                );
                                setValidateOnclick(true);
                                setIsOpen(false);
                            })
                    );
                } catch (e: any) {
                    toast.error(`${t("Error while Update user access")}!`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
        if (isConfirmAccessModificationOpened === true) {
            setIsConfirmAccessModification(false);
        }
    }

    async function HandleSaveUserAccessHistory() {
        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/history`;
        let data: any = [];

        if (dynamicTableWrapperContext?.rowData?.original) {
            const userHistory = dynamicTableWrapperContext?.rowData?.original;
            data = [
                {
                    typeHistory: 7,
                    oldData: {
                        Nom: `${userHistory.Nom}`,
                        Prénom: `${userHistory.Prénom}`,
                    },
                    newData: {
                        Nom: `${userHistory.Nom}`,
                        Prénom: `${userHistory.Prénom}`,
                    },
                    operator: responsable,
                    uidUpdatedData: `${userHistory.ID}`,
                },
            ];
        } else {
            selectedRows?.forEach((userItem: any) => {
                const data1 = {
                    typeHistory: 7,
                    oldData: {
                        Nom: `${userItem.Nom}`,
                        Prénom: `${userItem.Prénom}`,
                    },
                    newData: {
                        Nom: `${userItem.Nom}`,
                        Prénom: `${userItem.Prénom}`,
                    },
                    operator: responsable,
                    uidUpdatedData: `${userItem.ID}`,
                };
                data.push(data1);
            });
        }

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${keycloak.token}`,
                    },
                    body: JSON.stringify({
                        data,
                        customerAccountUUid: customerAccountId,
                    }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error();
                        }
                    })
            );
        } catch (e: any) {
            toast.error(`${t("Error while saving user account history")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        dynamicTableWrapperContext?.setCheckAccessUpdate(false);
    }

    function handleCloseModal() {
        handleSaveUserButtonOnClickEvent !== undefined &&
            handleSaveUserButtonOnClickEvent(userInfo);
        setIsOpen(!isOpen);
    }

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                isOpen={isOpen}
            >
                <ModalHeader
                    toggle={(): any => {
                        JSON.stringify(access) !==
                        localStorage.getItem("userAccessInfo")
                            ? setIsConfirmAccessModification(
                                  !isConfirmAccessModificationOpened
                              )
                            : handleCloseModal();
                    }}
                    className="text-uppercase cursor__clz"
                >
                    <span
                        className="ctl_txt_compteAccess"
                        style={{
                            textAlign: "left",
                            font: " normal normal bold 20px/14px Segoe UI",
                            color: "#2B2828",
                            fontWeight: 600,
                        }}
                    >
                        {t("ACCOUNT ACCESS MANAGEMENT")}
                    </span>
                </ModalHeader>
                <ModalBody className="p-0">
                    <ManageAccessModalContent
                        userId={userData?.ID}
                        selectedRows={selectedRows!}
                    />
                </ModalBody>
                <ModalFooter>
                    <StyledButton
                        className="ctl_btn_validatePopu"
                        rounded
                        variant={dominantColor}
                        onClick={() => {
                            handleManageAcesstOnClickEvent();
                        }}
                    >
                        {t("Validate")}
                    </StyledButton>
                </ModalFooter>
            </Modal>
            <ConfirmAccessModification
                isOpen={isConfirmAccessModificationOpened}
                setIsOpen={setIsConfirmAccessModification}
                // eslint-disable-next-line react/jsx-no-bind
                handleManageAcesstOnClickEvent={handleManageAcesstOnClickEvent}
                user={userInfo}
                handleSaveUserButtonOnClickEvent={
                    handleSaveUserButtonOnClickEvent
                }
                closeFirstModal={() => {
                    setIsOpen(!isOpen);
                }}
            />
        </React.Fragment>
    );
}
