import React from "react";

import ReactDOM from "react-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ReactKeycloakProvider } from "@react-keycloak/web";

import { keycloak } from "./keycloak";

import * as serviceWorker from "./serviceWorker";
import store from "./store";
import App from "./App";
import "./i18n";

const initOptions = {
    onLoad: "login-required",
};

function Main() {
    const persister = persistStore(store);
    return (
        <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persister}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </PersistGate>
                <ToastContainer />
            </Provider>
        </ReactKeycloakProvider>
    );
}
ReactDOM.render(<Main />, document.getElementById("root"));
serviceWorker.unregister();
