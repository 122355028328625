/* eslint-disable    react/self-closing-comp  */
/* eslint-disable    @typescript-eslint/no-unused-vars  */
/* eslint-disable    jsx-a11y/no-static-element-interactions */
/* eslint-disable    react/jsx-curly-brace-presence */
/* eslint-disable     no-unused-vars */

import React from "react";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import { useTranslation } from "react-i18next/";
import Avatar from "react-avatar";
import { cache } from "swr";
import { useNavigate } from "react-router-dom";
import { SketchPicker } from "react-color";
import { Tooltip } from "@mui/material";

import {
    ColorSvgIcon,
    KeySvgIcon,
    LogOutSvgIcon,
    MagicSvgIcon,
} from "@components/Common/SvgIcons";

import { setDominantColor } from "@store/features/colorSlice";

import { ArrowDownIcon } from "@components/Common/SvgIcons/ArrowDownIcon";
import RestoreSvgIcon from "@components/Common/SvgIcons/RestoreSvgIcon";

import "./layout.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/index";
import { useKeycloak } from "@react-keycloak/web";

type ProfileMenuType = {
    logout: Function;
    menu: boolean;
    setMenu: Function;
    name: string;
    email: any;
};

export default function ProfileMenu({
    logout,
    menu,
    setMenu,
    name,
    email,
}: ProfileMenuType) {
    const { keycloak } = useKeycloak();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const sketchPickerRef = React.useRef(null);

    const mainColor: string = useSelector(
        (State: RootState): string => State.colorSlice.dominantColor
    );

    const [colorMainPicker, setColorMainPicker] = React.useState(false);
    const [colorSecondaryPicker, setColorSecondaryMainPicker] =
        React.useState(false);

    const dispatch = useDispatch();

    function changePassword() {
        cache.clear();
        localStorage.clear();
        navigate("/update");
    }
    // eslint-disable-next-line  no-unused-vars
    const handleChangeSecondaryColor = (color: any) => {
        setColorSecondaryMainPicker(!colorSecondaryPicker);
        setColorMainPicker(false);
    };

    const handleChangeMainColor = () => {
        setColorMainPicker(!colorMainPicker);
        setColorSecondaryMainPicker(false);
    };
    const handleResetColor = () => {
        localStorage.setItem("mainColor", "#323333");
        dispatch(setDominantColor("#323333"));
        //  handleRequestColor("#171616", secondaryColor);
    };

    const handleSecondaryResetColor = () => {
        localStorage.setItem("secondaryColor", "#c4bfbe");
        //   setSecondaryColor("#c4bfbe");
        handleRequestColor(mainColor, "#c4bfbe");
    };

    React.useEffect(() => {
        setDominantColor(
            localStorage.getItem("mainColor") === undefined ||
                localStorage.getItem("mainColor") === "null" ||
                localStorage.getItem("mainColor") === ""
                ? "#171616"
                : localStorage.getItem("mainColor")!
        );

        // setSecondaryColor(
        //     localStorage.getItem("secondaryColor") === undefined ||
        //         localStorage.getItem("secondaryColor") === "null" ||
        //         localStorage.getItem("secondaryColor") === ""
        //         ? "#605e5d"
        //         : localStorage.getItem("secondaryColor")!
        // );
    }, []);

    async function handleRequestColor(mColor: string, sColor: string) {
        const urlVerifyUserAccount: string = `${process.env.REACT_APP_CONSOLE_ADMIN_API_URL}/userColor`;

        await fetch(urlVerifyUserAccount, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                authorization: `Bearer ${keycloak.token}`,
            },
            body: `email=${email}&mainColor=${mColor}&secondaryColor=${sColor}`,
            method: "POST",
        })
            .then((result) => result.json())
            .then(async (response: any) => {
                if (response.error) {
                    throw response.error;
                }
            })
            .catch((err: any) => {
                throw err;
            });
    }

    const handleClickOutsideColorPicker = async (event: any) => {
        if (
            sketchPickerRef.current &&
            !(sketchPickerRef.current as any).contains(event.target)
        ) {
            handleRequestColor(mainColor, "secondaryColor");
        }
    };

    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutsideColorPicker);
        return () => {
            document.removeEventListener(
                "mousedown",
                handleClickOutsideColorPicker
            );
        };
        // eslint-disable-next-line
    }, [mainColor]);

    return (
        <Dropdown
            isOpen={menu}
            className="d-inline-block"
            autoClose="outside"
            toggle={() => {
                setColorMainPicker(false);
                setMenu(!menu);
                setColorSecondaryMainPicker(false);
            }}
        >
            <DropdownToggle
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                tag="button"
            >
                <Avatar
                    className="mr-0"
                    name={name}
                    round
                    size="40"
                    color={mainColor}
                />
                <ArrowDownIcon fill="black" className="pt-1" />
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem>
                    <div
                        style={{
                            width: "300px",
                            overflow: "hidden",
                        }}
                        className="d-flex justify-content-start"
                    >
                        <Avatar
                            className="mt-2 mb-2"
                            name={name}
                            round
                            size="70"
                            maxInitials={2}
                            color={mainColor}
                        />
                        <div className="pt-4 pl-3">
                            <div className="line1__clz">{name}</div>
                            <div className="line2__clz">{email}</div>
                        </div>
                    </div>
                </DropdownItem>
                <hr style={{ color: "#000000" }} />
                <DropdownItem tag="a" onClick={() => changePassword()}>
                    <KeySvgIcon height="20" width="20" />
                    <i className="bx bx-lock-open font-size-16 align-middle mr-1 ml-3"></i>
                    {t("Change password")}
                </DropdownItem>
                <DropdownItem
                    style={{ pointerEvents: "none" }}
                    className="dropdown-item pl-4"
                >
                    <MagicSvgIcon height="25" width="25" fill="black" />
                    <i className="bx bx-power-off font-size-16 align-middle text-danger ml-3"></i>

                    <span>{t("Appearance")}</span>
                </DropdownItem>
                <DropdownItem
                    className="dropdown-item pl-5"
                    toggle={false}
                    onBlur={() => setColorMainPicker(!colorMainPicker)}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <span
                            style={{ pointerEvents: "none", cursor: "default" }}
                            className="ml-3 mr-3"
                        >
                            {t("Main color")}
                        </span>
                        <div
                            style={{
                                borderRadius: 3,
                                width: 60,
                                height: 20,
                                backgroundColor: mainColor,
                                marginRight: 10,
                            }}
                        ></div>

                        <Tooltip title={t("Pick main color")}>
                            <span
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <ColorSvgIcon
                                    onClick={handleChangeMainColor}
                                    height="20"
                                    width="20"
                                    color={mainColor}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip title={t("Restore main color")}>
                            <span onClick={handleResetColor} className="pl-3">
                                <RestoreSvgIcon
                                    height="20"
                                    width="20"
                                    stroke={"#171616"}
                                />
                            </span>
                        </Tooltip>
                        {colorMainPicker ? (
                            <div
                                style={{
                                    position: "fixed",
                                    top: "85%",
                                    right: "14%",
                                }}
                                ref={sketchPickerRef}
                            >
                                <SketchPicker
                                    color={mainColor}
                                    onChange={(color: any) => {
                                        localStorage.setItem(
                                            "mainColor",
                                            color.hex
                                        );
                                        dispatch(setDominantColor(color.hex));
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                </DropdownItem>
                {/* <DropdownItem
                    toggle={false}
                    className="dropdown-item pl-5"
                    onBlur={() => setColorSecondaryMainPicker(false)}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span className="ml-3 mr-2">
                            {t("Secondary color")}
                        </span>
                        <div
                            style={{
                                borderRadius: 3,
                                width: 60,
                                height: 20,
                                backgroundColor: secondaryColor,
                                marginRight: 10,
                            }}
                        ></div>
                        <Tooltip title={t("Pick secondary color")}>
                            <span onClick={handleChangeSecondaryColor}>
                                <ColorSvgIcon
                                    height="20"
                                    width="20"
                                    color={secondaryColor}
                                />
                            </span>
                        </Tooltip>

                        <Tooltip title={t("Restore secondary color")}>
                            <span
                                onClick={handleSecondaryResetColor}
                                className="pl-3"
                            >
                                <RestoreSvgIcon
                                    height="20"
                                    width="20"
                                    stroke={"#c4bfbe"}
                                />
                            </span>
                        </Tooltip>
                        {colorSecondaryPicker ? (
                            <div
                                ref={sketchPickerRef}
                                style={{
                                    position: "fixed",
                                    top: "86%",
                                    right: "14%",
                                }}
                            >
                                <SketchPicker
                                    color={secondaryColor}
                                    onChange={(color) => {
                                        localStorage.setItem(
                                            "secondaryColor",
                                            color.hex
                                        );
                                        setSecondaryColor(color.hex);
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                </DropdownItem> */}
                <DropdownItem
                    style={{ cursor: "pointer" }}
                    onClick={() => logout()}
                    className="dropdown-item"
                >
                    <LogOutSvgIcon height="25" width="25" fill="red" />
                    <i className="bx bx-power-off font-size-16 align-middle text-danger ml-3"></i>
                    <span>{t("Logout")}</span>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}
