import React from "react";
import { DynamicTable, customColumnProps } from "@aures/custom-react-table";
import { useKeycloak } from "@react-keycloak/web";

import { DynamicTableWrapperContext } from "@components/Common/DynamicTableWrapper";

import { useTranslation } from "react-i18next";

import "./index.css";

import VisibilityColmun from "./Colmun/VisibilityColmun";
import ConsultColmun from "./Colmun/ConsultColmun";
import EditColumn from "./Colmun/EditColmun";
import AuthorizationMultipleProfile from "./AuthorizationMultipleProfile";

type PropsType = {
    setlistSelectedData: Function;
    editProfile: boolean;
    setAuthMultiprofileModeOpen: React.Dispatch<React.SetStateAction<boolean>>;
    selectedRows: any[];
    setSelectedRows: React.Dispatch<React.SetStateAction<any[]>>;
};

export function ModalContentWrapper({
    setlistSelectedData,
    editProfile,
    setAuthMultiprofileModeOpen,
    selectedRows,
    setSelectedRows,
}: PropsType): JSX.Element {
    const { keycloak } = useKeycloak();
    const { t } = useTranslation();

    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );

    const [data, setData] = React.useState<any>({});

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const uuidEntity = localStorage.getItem("Uid_Entitie_User");

    const apiUrl = `${process.env.REACT_APP_CONSOLE_ADMIN_API_URL}/AccessAutorisation?uuid=${uuidEntity}`;

    const object: any = {};

    const Data: any = {};

    function getStructure(data: any, object: any) {
        data.forEach((element: any) => {
            Data[element.id] = {
                Id: element.id,
                IdParent: element.idParent,
                Visible: element.Visible,
                Consult: element.Consult,
                Modifie: element.Modifie,
            };
            if (element.subRows !== undefined && element.subRows.length !== 0) {
                getStructure(element.subRows, Data);
            }
        });

        return object;
    }

    const arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 5,
            columnName: t("Visibilité"),
            // eslint-disable-next-line react/no-unstable-nested-components,
            customJsx: (e: any): JSX.Element => (
                <VisibilityColmun
                    Data={data}
                    selectedRow={e?.selectedRow}
                    setlistSelectedData={setlistSelectedData}
                    editProfile={editProfile}
                />
            ),
        },
        {
            indexOFColumn: 6,
            columnName: t("Consultation"),
            // eslint-disable-next-line react/no-unstable-nested-components
            customJsx: (e: any): JSX.Element => (
                <ConsultColmun
                    Data={data}
                    selectedRow={e?.selectedRow}
                    setlistSelectedData={setlistSelectedData}
                    editProfile={editProfile}
                />
            ),
        },
        {
            indexOFColumn: 7,
            columnName: t("Modification"),
            // eslint-disable-next-line react/no-unstable-nested-components
            customJsx: (e: any): JSX.Element => (
                <EditColumn
                    Data={data}
                    selectedRow={e?.selectedRow}
                    setlistSelectedData={setlistSelectedData}
                    editProfile={editProfile}
                />
            ),
        },
    ];
    React.useEffect(() => {
        if (data.data !== undefined) {
            getStructure(data.data, object);
        }

        localStorage.setItem("dataProject", JSON.stringify(Data));
        // eslint-disable-next-line
    }, [data]);

    return (
        <DynamicTable
            url={apiUrl}
            setData={setData}
            canResize
            filterActive={filterActive}
            setLocalFilterActive={setLocalFilterActive}
            customJsxSideFilterButton={
                <AuthorizationMultipleProfile
                    selectedRows={selectedRows}
                    setAuthMultiprofileModeOpen={setAuthMultiprofileModeOpen}
                />
            }
            customSelect
            canSelect={!dynamicTableWrapperContext?.checkModifMultiple}
            setSelectedRows={setSelectedRows}
            showGlobalFilter
            canExpand
            showFilter
            arrayOfCustomColumns={arrayOfCustomColumns}
            setDataIsUpdated={setDataIsUpdated}
            dataIsUpdated={dataIsUpdated}
            canMovedCheckboxLeftOnExpand
            requestHeader={{ authorization: `Bearer ${keycloak.token}` }}
        />
    );
}
