/* eslint-disable no-nested-ternary */
import React from "react";
import { useKeycloak } from "@react-keycloak/web";

import { StyledButton } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader, Card } from "reactstrap";
import { DynamicTable } from "@aures/custom-react-table";

import { DynamicTableWrapperContext } from "@components/Common";

export default function HistoryModal({
    setIsModalOpened,
    isModalOpened,
    type,
}: any) {
    const { keycloak } = useKeycloak();
    const { t } = useTranslation();
    const customerAccountId = localStorage.getItem("customerAccountId");

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);

    let uuid: any = [];
    if (dynamicTableWrapperContext?.rowData?.original) {
        // Type 7: user history
        if (type === 7) {
            uuid = ` '${dynamicTableWrapperContext?.rowData?.original.ID}'`;
        } else {
            uuid = `'${dynamicTableWrapperContext?.rowData?.original.uid}'`;
        }
    } else {
        Object.values(dynamicTableWrapperContext?.rowData).forEach(
            (userItem: any, index: number) => {
                // Type 7: user history
                if (type === 7) {
                    if (index !== 0) {
                        uuid += `, '${userItem.ID}'`;
                    } else {
                        uuid += `'${userItem.ID}'`;
                    }
                    // Type 4: Profile history
                } else if (type === 4) {
                    if (index !== 0) {
                        uuid += `, '${userItem.uid}'`;
                    } else {
                        uuid += `'${userItem.uid}'`;
                    }
                }
            }
        );
    }

    return (
        <Modal
            centered
            backdrop="static"
            fade={false}
            scrollable
            className="modal-wrapper__clz"
            isOpen={isModalOpened}
        >
            <ModalHeader
                toggle={() => {
                    setIsModalOpened!(!isModalOpened);
                }}
            >
                <span
                    style={{
                        fontFamily: "Segoe UI Semibold",
                        color: "#2b2828",
                        fontSize: "20px",
                        textTransform: "uppercase",
                    }}
                >
                    {dynamicTableWrapperContext?.rowData?.original
                        ? type === 4
                            ? t("Profile history")
                            : t("User history")
                        : type === 4
                        ? t("Profiles history")
                        : t("Users history")}
                </span>
            </ModalHeader>

            <ModalBody>
                {dynamicTableWrapperContext?.rowData?.original ? (
                    <Card
                        style={{
                            width: "100%",
                            height: "56px",
                            background: "#E5E4E4AB 0% 0% no-repeat padding-box",
                            border: "1px solid #E5E4E4",
                        }}
                    >
                        <span
                            style={{
                                left: "236px",
                                width: "max-content",
                                height: "27px",
                                textAlign: "left",
                                font: "normal normal bold 20px/20px Segoe UI",
                                color: "#2B2828",
                                marginTop: "15px",
                                marginLeft: "10px",
                            }}
                        >
                            {type === 4
                                ? `${t("Designation")} : ${
                                      dynamicTableWrapperContext?.rowData
                                          ?.original.Désignation
                                  }`
                                : `${t("User Account")} : ${
                                      dynamicTableWrapperContext?.rowData
                                          ?.original.Nom
                                  } ${
                                      dynamicTableWrapperContext?.rowData
                                          ?.original.Prénom
                                  }`}
                        </span>
                    </Card>
                ) : null}
                <DynamicTable
                    url={`${process.env.REACT_APP_ACCESS_API_URL}/history?customerAccountUUid=${customerAccountId}&uid="${uuid}"&type=${type}`}
                    canResize
                    canSort
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight="500px"
                    maxHeight="500px"
                    name="historyModeOfSale"
                    requestHeader={{
                        authorization: `Bearer ${keycloak.token}`,
                    }}
                />
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    rounded
                    outline
                    variant="light"
                    className="mdv_btn_HSclose"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {t("Close")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
