

export function isEmailValid(email: string) {
    const EMAIL_VALIDATION_REGEX = /\S+@\S+\.\S+/;
    return EMAIL_VALIDATION_REGEX.test(email);
}

export function truncateString(string: string, maxLength: number): string {
    return string?.length > maxLength
        ? `${string?.slice(0, maxLength)}...`
        : string;
}