/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";

import { DynamicTable } from "@aures/custom-react-table";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";

import {
    USER_ARCHIVED_ACCESS_ATHORIZATION_ID,
    statusArchived,
} from "@constants/index";

import { useSelector } from "react-redux";
import { RootState } from "@store/index";

import ConfirmUnArchivedModal from "@components/Common/Modals/ConfirmUnArchivedModal";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";
import { DynamicTableWrapperContext } from "@components/Common";
import { ConsultIcon } from "@components/Common/SvgIcons/ConsultIcon";
import HistoryModal from "@components/Common/Modals/HistoryModal";

import ArchivedMultiAction from "./ArchivedMultiAction";
import ArchivedActionColumn from "./ArchivedActionColumn";

import "../UserAccounts/style.css";

export function ArchivedAccountList(): JSX.Element {
    const { keycloak } = useKeycloak();
    const { t } = useTranslation();
    const i18nextLng = localStorage.getItem("i18nextLng") as string;

    const authorization = useSelector(
        (state: RootState) => state.ProfileAuthorizationSlice.data
    );

    const isVisible = true;
    const customerAccountId = localStorage.getItem("customerAccountId");

    const userAccountStatus = statusArchived;
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [isHistoryModalOpened, setIsHistoryModalOpened] =
        React.useState<boolean>(false);

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const [isConfirmUnArchivedModal, setIsConfirmUnArchivedModal] =
        React.useState<boolean>(false);

    const customJsxActions = (e: any) => (
        <div
            className={`car_icn_consult d-flex justify-content-around w-50 ${
                authorization[USER_ARCHIVED_ACCESS_ATHORIZATION_ID] !==
                    undefined &&
                authorization[USER_ARCHIVED_ACCESS_ATHORIZATION_ID].Modifie
                    ? ""
                    : "disabled_clz"
            }`}
        >
            <div id={`Duplication${e?.selectedRow?.iuud}`}>
                <LockIconWithToolTip
                    message={t(`Consulter`)}
                    id={`Duplication${e?.selectedRow?.iuud}`}
                />

                <div
                    onClick={() => {
                        dynamicTableWrapperContext?.setRowData(e.selectedRow);
                        dynamicTableWrapperContext?.setLocalFilterActive(false);
                        dynamicTableWrapperContext?.setActionColumnDisplay(
                            true
                        );
                    }}
                >
                    <ConsultIcon height={40} width={40} />
                </div>
            </div>
        </div>
    );

    function handleHistorysOnClickEvent(selectedRow: any) {
        setIsHistoryModalOpened(!isHistoryModalOpened);
        dynamicTableWrapperContext?.setRowData({ ...selectedRow });
    }

    const arrayOfCustomColumns: any[] = [
        {
            indexOFColumn: 99,
            columnName: t("Actions"),
            customJsx: customJsxActions,
        },
    ];

    return (
        <React.Fragment>
            <DynamicTable
                url={`${process.env.REACT_APP_ACCESS_API_URL}/users?customerAccountUUid=${customerAccountId}&userAccountStatus=${userAccountStatus}&customerLang=${i18nextLng}`}
                canSort
                canSelect
                customSelect
                setSelectedRows={setSelectedRows}
                name="table-user-Archived"
                // eslint-disable-next-line react/no-unstable-nested-components
                actionColumn={(e: any) => {
                    return (
                        <ArchivedActionColumn
                            e={e}
                            setIsConfirmUnArchivedModal={
                                setIsConfirmUnArchivedModal
                            }
                            isConfirmUnArchivedModal={isConfirmUnArchivedModal}
                            // eslint-disable-next-line react/jsx-no-bind
                            handleHistorysOnClickEvent={
                                handleHistorysOnClickEvent
                            }
                        />
                    );
                }}
                customJsxSideFilterButton={
                    <div className="d-flex flex-row align-items-center ml-2">
                        <div className="d-flex justify-content-end align-items-end">
                            <ArchivedMultiAction
                                selectedRows={selectedRows}
                                setIsConfirmUnArchivedModal={
                                    setIsConfirmUnArchivedModal
                                }
                                // eslint-disable-next-line react/jsx-no-bind
                                handleHistorysOnClickEvent={
                                    handleHistorysOnClickEvent
                                }
                            />
                        </div>
                    </div>
                }
                arrayOfCustomColumns={arrayOfCustomColumns}
                setDataIsUpdated={dynamicTableWrapperContext?.setDataUpdated}
                dataIsUpdated={dynamicTableWrapperContext?.dataIsUpdated}
                canResize
                showGlobalFilter
                showFilter
                minHeight={`calc(100vh - (370px + ${
                    isVisible ? "100px" : "0px"
                }))`}
                maxHeight={`calc(100vh - (370px + ${
                    isVisible ? "125px" : "0px"
                }))`}
                requestHeader={{ authorization: `Bearer ${keycloak.token}` }}
            />

            <ConfirmUnArchivedModal
                isOpen={isConfirmUnArchivedModal}
                setIsOpen={setIsConfirmUnArchivedModal}
            />
            {isHistoryModalOpened ? (
                <HistoryModal
                    setIsModalOpened={setIsHistoryModalOpened}
                    isModalOpened={isHistoryModalOpened}
                    type={7}
                />
            ) : null}
        </React.Fragment>
    );
}
