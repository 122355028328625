/* eslint-disable no-lonely-if */
/* eslint-disable sonarjs/no-duplicated-branches */
import React from "react";
import { AvForm } from "availity-reactstrap-validation";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import { StyledButton, CheckboxIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSelector } from "react-redux";
import { Card, CardFooter } from "reactstrap";
import { RootState } from "@store/index";
import StyleCardHeader from "@components/Common/StyledComponent/StyledCardHeader";
import "react-phone-number-input/style.css";
import {
    DominantColor,
    DynamicTableWrapperContext,
    RequirementToast,
} from "@components/Common";
import MangeProfilePermissions from "@components/Common/Modals/ManageProfilePermissions/MangeProfilePermissions";
import { APPLICATION_UID } from "@constants/index";
import { FormBody } from "./FormBody";

import "../style.css";

export function AddProfile(): JSX.Element {
    const { keycloak } = useKeycloak();
    const { t } = useTranslation();

    const headers = {
        "Content-Type": "application/json",
        authorization: `Bearer ${keycloak.token}`,
    };

    const [isManagePermissionsModalOpened, setIsManagePermissionsModalOpened] =
        React.useState<boolean>(false);
    const responsable = localStorage.getItem("Name");
    const [listSelectedData, setlistSelectedData] = React.useState<any>({});
    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);

    const profileStatus = "Activé";
    const refs = React.useRef<HTMLDivElement[]>([]);
    const dominantColor: string = DominantColor();
    const customerAccountId = localStorage.getItem("customerAccountId");
    const [isDesignationExist, setIsDesignationExist] = React.useState(false);
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const isProfileListArchived = useSelector(
        (state: RootState) => state.profileSlice.isProfileListArchived
    );

    const [invalidSubmit, setInvalidSubmit] = React.useState(false);

    const [profile, setProfile] = React.useState<ProfileType>({
        Désignation: "",
        Balise: "",
        Description: "",
        autorisation: {},
    });

    const initialProfilData: ProfileType = {
        Désignation: dynamicTableWrapperContext?.rowData?.Désignation,
        Balise: dynamicTableWrapperContext?.rowData?.Balise,
        Description: dynamicTableWrapperContext?.rowData?.Description,
        autorisation: dynamicTableWrapperContext?.rowData?.autorisation,
    };

    const addToRefs = (el: HTMLDivElement) => {
        if (el && !refs?.current?.includes(el)) {
            refs?.current?.push(el);
        }
    };

    React.useEffect(() => {
        if (
            profile.Désignation !== "" &&
            dynamicTableWrapperContext?.rowData !== undefined
        ) {
            if (
                profile.Désignation ===
                dynamicTableWrapperContext?.rowData.Désignation
            ) {
                setIsDesignationExist(false);
            } else {
                const data = dynamicTableWrapperContext?.dataTable.data.filter(
                    (item: any) => item.Désignation === profile.Désignation
                );
                if (data.length > 0) {
                    setIsDesignationExist(true);
                } else {
                    setIsDesignationExist(false);
                }
            }
        }
    }, [
        dynamicTableWrapperContext?.dataTable.data,
        dynamicTableWrapperContext?.rowData?.Désignation,
        profile,
        dynamicTableWrapperContext?.rowData,
    ]);

    React.useEffect(() => {
        if (dynamicTableWrapperContext?.rowData?.Désignation !== "") {
            setProfile({
                Désignation: dynamicTableWrapperContext?.rowData?.Désignation,
                Balise: dynamicTableWrapperContext?.rowData?.Balise,
                Description: dynamicTableWrapperContext?.rowData?.Description,
                autorisation: dynamicTableWrapperContext?.rowData?.autorisation,
            });
        }
    }, [
        dynamicTableWrapperContext?.rowData?.Balise,
        dynamicTableWrapperContext?.rowData?.Description,
        dynamicTableWrapperContext?.rowData?.Désignation,
        dynamicTableWrapperContext?.rowData?.autorisation,
    ]);

    // eslint-disable-next-line sonarjs/cognitive-complexity
    async function handleSaveProfileButtonOnClickEvent() {
        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/add-profile`;
        const dataProject: any = JSON.parse(
            localStorage.getItem("dataProject") as string
        );
        const copyData = {
            ...dataProject,
            ...dynamicTableWrapperContext?.multiAuthorizationProfileData,
        };

        Object.keys(copyData).forEach((el: any) => {
            Object.keys(listSelectedData).forEach((elData: any) => {
                if (el === elData) {
                    copyData[el] = listSelectedData[elData];
                }
            });
        });
        Object.values(copyData).forEach((Element: any) => {
            if (APPLICATION_UID.includes(Element.Id)) {
                copyData[Element.Id].Visible = true;
                copyData[Element.Id].Consult = true;
            }
        });

        Object.values(copyData).forEach((Element: any) => {
            if (Element.IdParent === Element.Id) {
                if (copyData[Element.Id].Visible === true) {
                    if (copyData[Element.Id].Consult === false) {
                        copyData[Element.Id].Modifie = false;
                    }
                } else {
                    copyData[Element.Id].Consult = false;
                    copyData[Element.Id].Modifie = false;
                }
            } else {
                if (copyData[Element.IdParent].Visible === false) {
                    copyData[Element.Id].Visible = false;
                    copyData[Element.Id].Consult = false;
                    copyData[Element.Id].Modifie = false;
                } else if (
                    copyData[Element.IdParent].Visible === true &&
                    copyData[Element.IdParent].Consult === false
                ) {
                    copyData[Element.Id].Visible = false;
                    copyData[Element.Id].Consult = false;
                    copyData[Element.Id].Modifie = false;
                } else if (
                    copyData[Element.IdParent].Visible === true &&
                    copyData[Element.IdParent].Consult === true
                ) {
                    if (copyData[Element.Id].Visible === false) {
                        copyData[Element.Id].Visible = false;
                        copyData[Element.Id].Consult = false;
                        copyData[Element.Id].Modifie = false;
                    } else if (
                        copyData[Element.Id].Visible === true &&
                        (copyData[Element.Id].Consult === false ||
                            copyData[Element.IdParent].Modifie === false)
                    ) {
                        copyData[Element.Id].Modifie = false;
                    }
                }
            }
        });

        profile.autorisation = copyData;
        if (validateOnclick) {
            setValidateOnclick(false);
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers,
                        body: JSON.stringify({
                            customerAccountUUid: customerAccountId,
                            data: profile,
                        }),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                            setValidateOnclick(true);
                            toast.success(
                                `${t("The profile is successfully added")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );

                            dynamicTableWrapperContext?.setDataUpdated(true);
                            dynamicTableWrapperContext?.setActionColumnDisplay(
                                false
                            );
                        })
                );
            } catch (e: any) {
                toast.error(`${t("There's an error")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    async function handleEditedProfileButtonOnClickEvent() {
        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/edit-profile?profileStatus=${profileStatus}`;

        if (validateOnclick) {
            try {
                setValidateOnclick(false);
                const dataProfiles: any = [];
                dataProfiles.push({
                    ...profile,
                    uid: dynamicTableWrapperContext?.rowData.uid,
                });
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers,
                        body: JSON.stringify({
                            customerAccountUUid: customerAccountId,
                            profiles: {
                                dataProfiles,
                            },
                        }),
                        method: "PUT",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                            if (!invalidSubmit) {
                                handleSavedHistoryOnClickEvent();
                            }
                            setValidateOnclick(true);
                            toast.success(
                                `${t("The profile is successfully updated")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );

                            dynamicTableWrapperContext?.setDataUpdated(true);
                            dynamicTableWrapperContext?.setActionColumnDisplay(
                                false
                            );
                        })
                );
            } catch (e: any) {
                toast.error(`${t("There's an error")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
            setInvalidSubmit(false);
        }
    }
    // This Function only for ADD & UPDATE Profile Acount History
    // For Update Profile Authorization History on ProfileList.tsx
    async function handleSavedHistoryOnClickEvent() {
        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/history`;

        const data = [
            {
                typeHistory: 4,

                oldData: {
                    designation:
                        dynamicTableWrapperContext?.rowData.Désignation,
                    Balise: dynamicTableWrapperContext?.rowData?.Balise,
                    Description:
                        dynamicTableWrapperContext?.rowData?.Description,
                },
                newData: {
                    designation: profile.Désignation,
                    Balise: profile.Balise,
                    Description: profile.Description,
                },
                operator: responsable,
                uidUpdatedData: dynamicTableWrapperContext?.rowData?.uid,
            },
        ];

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers,
                    body: JSON.stringify({
                        data,
                        customerAccountUUid: customerAccountId,
                    }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error();
                        }
                    })
            );
        } catch (e: any) {
            toast.error(`${t("Error while Save Profile History")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    const handleRefreshIconClick = (): void => {
        if (dynamicTableWrapperContext?.rowData?.Désignation !== "") {
            setProfile({
                Désignation: dynamicTableWrapperContext?.rowData?.Désignation,
                Balise: dynamicTableWrapperContext?.rowData?.Balise,
                Description: dynamicTableWrapperContext?.rowData?.Description,
                autorisation: dynamicTableWrapperContext?.rowData?.Description,
            });
        } else {
            setProfile({
                Désignation: "",
                Balise: "",
                Description: "",
                autorisation: {},
            });
        }
    };

    const handleOnscroll = (): void => {
        if (refs) {
            refs?.current?.forEach((elm: any) => {
                // eslint-disable-next-line sonarjs/no-collapsible-if
                if (profile.Désignation === "") {
                    if (elm.id === "Désignation") {
                        elm.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                            inline: "start",
                        });
                        elm.focus();
                        RequirementToast(
                            t("The ''Désignation'' field must be filled")
                        );
                    }
                }
            });
        }
    };

    React.useEffect(() => {
        dynamicTableWrapperContext?.setAddedProfile(profile);
    }, [dynamicTableWrapperContext, profile]);

    return (
        <React.Fragment>
            <Card style={{ border: "0px " }}>
                {isProfileListArchived === true ? (
                    <StyleCardHeader title={t("View archived profile")} />
                ) : (
                    <StyleCardHeader
                        title={
                            dynamicTableWrapperContext?.rowData?.Désignation !==
                            ""
                                ? t("Edit profile")
                                : t("Add profile")
                        }
                        refreshIconFunction={handleRefreshIconClick}
                    />
                )}

                <AvForm
                    onValidSubmit={() => {
                        if (
                            dynamicTableWrapperContext?.rowData?.Désignation !==
                                "" &&
                            !isDesignationExist
                        ) {
                            if (
                                profile.Désignation ===
                                    initialProfilData.Désignation &&
                                profile.Description ===
                                    initialProfilData.Description &&
                                profile.Balise === initialProfilData.Balise
                            ) {
                                dynamicTableWrapperContext?.setActionColumnDisplay(
                                    false
                                );
                            } else {
                                handleEditedProfileButtonOnClickEvent();
                            }
                        } else if (isDesignationExist === true) {
                            RequirementToast(
                                t("The ''Désignation''is already existed")
                            );
                            setIsManagePermissionsModalOpened(false);
                        } else {
                            setIsManagePermissionsModalOpened(
                                !isManagePermissionsModalOpened
                            );

                            //  handleSaveProfileButtonOnClickEvent();
                        }
                    }}
                    onInvalidSubmit={() => {
                        handleOnscroll();
                        setInvalidSubmit(true);
                    }}
                    onKeyDown={(e: any) => {
                        if (e.key === "Enter") e.preventDefault();
                    }}
                >
                    <FormBody
                        profile={profile}
                        setProfile={setProfile}
                        addToRefs={addToRefs}
                        isDesignationExist={isDesignationExist}
                    />
                    {isProfileListArchived === true ? (
                        <CardFooter className="Footercard p-0 ">
                            <div className=" d-flex flex-wrap d-flex flex-row-reverse bd-highlight ">
                                <div className="p-2 bd-highlight pfl_btn_close">
                                    <StyledButton
                                        rounded
                                        outline
                                        variant="light"
                                        onClick={() => {
                                            dynamicTableWrapperContext?.setActionColumnDisplay(
                                                false
                                            );
                                        }}
                                    >
                                        {t("Close")}
                                    </StyledButton>
                                </div>
                            </div>
                        </CardFooter>
                    ) : (
                        <CardFooter className="Footercard p-0 ">
                            <div className=" d-flex flex-wrap d-flex flex-row-reverse bd-highlight ">
                                <div className="p-2 bd-highlight">
                                    <StyledButton
                                        className="pfl_btn_validate"
                                        rounded
                                        variant={dominantColor}
                                        onClick={() => {
                                            dynamicTableWrapperContext?.setIsEdit(
                                                false
                                            );
                                        }}
                                    >
                                        {t("Validate")}
                                    </StyledButton>
                                </div>
                                <div className="p-2 bd-highlight ">
                                    <StyledButton
                                        className="pfl_btn_cancel"
                                        rounded
                                        outline
                                        variant="light"
                                        onClick={() => {
                                            dynamicTableWrapperContext?.setActionColumnDisplay(
                                                false
                                            );
                                            dynamicTableWrapperContext?.setIsEdit(
                                                false
                                            );
                                        }}
                                    >
                                        {t("Cancel")}
                                    </StyledButton>
                                </div>
                            </div>
                        </CardFooter>
                    )}
                </AvForm>
            </Card>
            <MangeProfilePermissions
                isOpen={isManagePermissionsModalOpened}
                setIsOpen={setIsManagePermissionsModalOpened}
                // eslint-disable-next-line react/jsx-no-bind
                handleSaveButtonOnClickEvent={
                    handleSaveProfileButtonOnClickEvent
                }
                setlistSelectedData={setlistSelectedData}
                editProfile={false}
            />
        </React.Fragment>
    );
}
