import React from "react";
import { useKeycloak } from "@react-keycloak/web";

import { SpinnerWrapper } from "@components/Common/SpinnerWrapper";

import "./app.css";
import { setDominantColor } from "@store/features/colorSlice";
import { useDispatch } from "react-redux";
import Router from "./routes";

export default function App() {
    const dispatch = useDispatch();
    // if (process.env.REACT_APP_IS_SSO_ACTIVE === "false") {
    //     localStorage.setItem(
    //         "Uid_Entitie_User",
    //         "8ecfe5ee-926b-423e-8050-c911dd81c147"
    //     );
    // }
    dispatch(setDominantColor( localStorage.getItem(
        "mainColor"
        
    ) !== null ? (localStorage.getItem(
        "mainColor"
        
    )as string ) : "#323333"));

    const {  initialized } = useKeycloak();
   
    if (!initialized) {
        return <React.Suspense fallback={<SpinnerWrapper />}> </React.Suspense>;
    }
    return <Router />;
}
