/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";

import { DynamicTable } from "@aures/custom-react-table";
import { useTranslation } from "react-i18next";
import { PencilIcon } from "@aureskonnect/react-ui";
import { useKeycloak } from "@react-keycloak/web";

import { useSelector } from "react-redux";
import { RootState } from "@store/index";

import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";

import { DynamicTableWrapperContext } from "@components/Common";
import ConfirmArchivedModal from "@components/Common/Modals/ConfirmArchivedModal";
import ConfirmUnBlockModal from "@components/Common/Modals/ConfirmUnBlockModal";
import HistoryModal from "@components/Common/Modals/HistoryModal";

import {
    StatusBloquer,
    USER_BLOCKED_ACCESS_ATHORIZATION_ID,
} from "@constants/index";

import "../UserAccounts/style.css";
import BlockActionColumn from "./BlockActionColumn";
import BlocMultiActionColumn from "./BlocMultiActionColumn";

export function BlockedAccountList() {
    const { keycloak } = useKeycloak();

    const { t } = useTranslation();
    const i18nextLng = localStorage.getItem("i18nextLng") as string;

    const customerAccountId = localStorage.getItem("customerAccountId");

    const authorization = useSelector(
        (state: RootState) => state.ProfileAuthorizationSlice.data
    );

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const isVisible = true;

    const [isConfirmBlockModal, setIsConfirmBlockModal] = React.useState(false);
    const [isConfirmArchivedModal, setIsConfirmArchivedModal] =
        React.useState<boolean>(false);
    const [isHistoryModalOpened, setIsHistoryModalOpened] =
        React.useState<boolean>(false);

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);

    function handleOnEditUser(user: any) {
        dynamicTableWrapperContext?.setRowData({ ...user });
    }
    function handleHistorysOnClickEvent(selectedRow: any) {
        setIsHistoryModalOpened(!isHistoryModalOpened);
        dynamicTableWrapperContext?.setRowData({ ...selectedRow });
    }

    const customJsxActions = (e: any) => (
        <div className="d-flex justify-content-around w-50">
            <div id={`Duplication${e?.selectedRow?.iuud}`}>
                <LockIconWithToolTip
                    message={t(`Duplicate`)}
                    id={`Duplication${e?.selectedRow?.iuud}`}
                />
            </div>

            <div
                id={`Modifier${e?.selectedRow?.iuud}`}
                className={`${
                    authorization[USER_BLOCKED_ACCESS_ATHORIZATION_ID] !==
                        undefined &&
                    authorization[USER_BLOCKED_ACCESS_ATHORIZATION_ID].Modifie
                        ? ""
                        : "disabled_clz"
                } cmn_icn_edit`}
            >
                <LockIconWithToolTip
                    message={t(`Edit`)}
                    id={`Modifier${e?.selectedRow?.iuud}`}
                />
                <PencilIcon
                    style={{ cursor: "pointer" }}
                    height={25}
                    width={25}
                    onClick={() => {
                        handleOnEditUser(e.selectedRow);
                        dynamicTableWrapperContext?.setLocalFilterActive(false);
                        dynamicTableWrapperContext?.setActionColumnDisplay(
                            true
                        );
                    }}
                />
            </div>
        </div>
    );

    const arrayOfCustomColumns: any[] = [
        {
            indexOFColumn: 99,
            columnName: t("Actions"),
            customJsx: customJsxActions,
        },
    ];
    return (
        <React.Fragment>
            <DynamicTable
                url={`${process.env.REACT_APP_ACCESS_API_URL}/users?customerAccountUUid=${customerAccountId}&userAccountStatus=${StatusBloquer}&customerLang=${i18nextLng}`}
                canSort
                name="Option_table"
                canResize
                showGlobalFilter
                showFilter
                canSelect
                customSelect
                setSelectedRows={setSelectedRows}
                // eslint-disable-next-line react/no-unstable-nested-components
                actionColumn={(e: any) => {
                    return (
                        <BlockActionColumn
                            e={e}
                            setIsConfirmBlockModal={setIsConfirmBlockModal}
                            setIsConfirmArchivedModal={
                                setIsConfirmArchivedModal
                            }
                            // eslint-disable-next-line react/jsx-no-bind
                            handleHistorysOnClickEvent={
                                handleHistorysOnClickEvent
                            }
                        />
                    );
                }}
                arrayOfCustomColumns={arrayOfCustomColumns}
                setDataIsUpdated={dynamicTableWrapperContext?.setDataUpdated}
                dataIsUpdated={dynamicTableWrapperContext?.dataIsUpdated}
                customJsxSideFilterButton={
                    <div className="d-flex flex-row align-items-center ml-2">
                        <div className="d-flex justify-content-end align-items-end">
                            <BlocMultiActionColumn
                                selectedRows={selectedRows}
                                setIsConfirmBlockModal={setIsConfirmBlockModal}
                                setIsConfirmArchivedModal={
                                    setIsConfirmArchivedModal
                                }
                                // eslint-disable-next-line react/jsx-no-bind
                                handleHistorysOnClickEvent={
                                    handleHistorysOnClickEvent
                                }
                            />
                        </div>
                    </div>
                }
                minHeight={`calc(100vh - (370px + ${
                    isVisible ? "100px" : "0px"
                }))`}
                maxHeight={`calc(100vh - (370px + ${
                    isVisible ? "125px" : "0px"
                }))`}
                requestHeader={{ authorization: `Bearer ${keycloak.token}` }}
            />
            <ConfirmUnBlockModal
                isOpen={isConfirmBlockModal}
                setIsOpen={setIsConfirmBlockModal}
            />
            <ConfirmArchivedModal
                isOpen={isConfirmArchivedModal}
                setIsOpen={setIsConfirmArchivedModal}
            />
            {isHistoryModalOpened ? (
                <HistoryModal
                    setIsModalOpened={setIsHistoryModalOpened}
                    isModalOpened={isHistoryModalOpened}
                    type={7}
                />
            ) : null}
        </React.Fragment>
    );
}
