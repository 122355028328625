import React from "react";
import { useTranslation } from "react-i18next";
import { TYPEBoutique, PROFILE_ACCESS_ATHORIZATION_ID } from "@constants/index";
import { UnArchiveIcon } from "@components/Common/SvgIcons/UnArchiveIcon";
import { DocumentIcon, SettingsIcon, TrashIcon } from "@aureskonnect/react-ui";
import { DynamicTableWrapperContext } from "@components/Common";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";
import { useKeycloak } from "@react-keycloak/web";

type ActionMultipleProfileType = {
    isProfileListArchived: boolean;
    selectedRows: any[];
    setIsConfirmUnArchivedModal: React.Dispatch<React.SetStateAction<boolean>>;
    setIsConfirmArchivedModal: React.Dispatch<React.SetStateAction<boolean>>;
    // eslint-disable-next-line react/no-unused-prop-types
    setIsManagePermissionsModalOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setIsAuthorisationProfileAlert: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    handleHistoryOnClickEvent: Function;
    handleManagePermissionsOnClickEvent: Function;
};
// Action Multiple archiver/unarchiver, History, and Manage permissions for ADMIN ONLY
function ActionMultipleProfile({
    isProfileListArchived,
    selectedRows,
    setIsConfirmUnArchivedModal,
    setIsConfirmArchivedModal,
    setIsAuthorisationProfileAlert,
    handleHistoryOnClickEvent,
    handleManagePermissionsOnClickEvent,
}: ActionMultipleProfileType) {
    const { keycloak } = useKeycloak();
    const { t } = useTranslation();
    const TypeAccount = localStorage.getItem("TypeAccount");

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const authorization = useSelector(
        (state: RootState) => state.ProfileAuthorizationSlice.data
    );

    const [adminSelected, setAdminSelected] = React.useState<boolean>(false);

    const errorTost = () => {
        return toast.error(
            `${t("This action is not allowed on the administrator profile")}`,
            {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            }
        );
    };

    async function handleMultiProfileArchiverAction(selectedRows: any[]) {
        let dataProfileAccount: string = "";
        if (!adminSelected) {
            try {
                selectedRows.map((profileData: any, index: number) => {
                    if (index !== 0) {
                        dataProfileAccount += `, '${profileData.uid}'`;
                    } else {
                        dataProfileAccount += `'${profileData.uid}'`;
                    }
                    return profileData;
                });

                const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/check-profile-used?profilesUUid=${dataProfileAccount}`;
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${keycloak.token}`,
                        },
                        method: "GET",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.check) {
                                throw Error();
                            }
                            dynamicTableWrapperContext?.setRowData(
                                dataProfileAccount
                            );
                            setIsConfirmArchivedModal(true);
                        })
                );
            } catch (error) {
                toast.error(
                    `${t(
                        "Please, cannot archive profile(s) used by one or more user accounts."
                    )}!`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
            }
        } else {
            errorTost();
        }
    }

    function handleProfileUnArchiveAction(selectedRows: any[]) {
        if (!adminSelected) {
            dynamicTableWrapperContext?.setRowData(selectedRows);
            setIsConfirmUnArchivedModal(true);
        } else {
            errorTost();
        }
    }

    async function handleMultiProfileAccess(selectedRows: any[]) {
        let Uid = "";
        selectedRows.forEach((selectedProfile, index) => {
            if (index !== 0) {
                Uid += `, '${selectedProfile.uid}'`;
            } else {
                Uid += `'${selectedProfile.uid}'`;
            }
        });
        if (!adminSelected) {
            const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/check-profile-autorisation?profilesUUid=${Uid}`;
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${keycloak.token}`,
                        },
                        method: "GET",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.check) {
                                handleManagePermissionsOnClickEvent(
                                    selectedRows
                                );
                            } else {
                                setIsAuthorisationProfileAlert(true);
                            }
                        })
                );
            } catch {
                // eslint-disable-next-line no-console
                console.error();
            }
            dynamicTableWrapperContext?.setRowData(selectedRows);
        } else {
            errorTost();
        }
    }

    React.useEffect(() => {
        // Check if Admin account on selected rows
        const checkIsAdmin = () => {
            const adminList = selectedRows?.filter(
                (item: any) => item.isProfileAdmin === true
            );
            if (adminList?.length !== 0) {
                setAdminSelected(true);
            } else {
                setAdminSelected(false);
            }
        };
        checkIsAdmin();
    }, [dynamicTableWrapperContext, selectedRows]);

    return (
        <>
            {/* Manage Account Access ONLY Admin action */}
            {TypeAccount !== TYPEBoutique && isProfileListArchived === false && (
                <div
                    role="button"
                    tabIndex={-2}
                    className={`dropdown-item pl-1 cmn_btn_managePermissions ${
                        (selectedRows as any[]).length > 0 &&
                        authorization[PROFILE_ACCESS_ATHORIZATION_ID] !==
                            undefined &&
                        authorization[PROFILE_ACCESS_ATHORIZATION_ID].Modifie
                            ? ""
                            : "disabled_clz"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        handleMultiProfileAccess(selectedRows);
                        dynamicTableWrapperContext?.setCheckModifMultiple(true);
                    }}
                >
                    <SettingsIcon width={35} height={25} />
                    <span className="ml-2">{t("Manage permissions")}</span>
                </div>
            )}
            {/* Display Account History */}
            <div
                role="button"
                tabIndex={-1}
                className={`dropdown-item pl-1 cmn_btn_multipleHistorical ${
                    (selectedRows as any[]).length > 0 &&
                    authorization[PROFILE_ACCESS_ATHORIZATION_ID] !==
                        undefined &&
                    authorization[PROFILE_ACCESS_ATHORIZATION_ID].Consult
                        ? ""
                        : "disabled_clz"
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                    handleHistoryOnClickEvent(selectedRows);
                }}
            >
                <DocumentIcon width={40} height={30} />
                <span className="ml-2">{t("History")}</span>
            </div>
            {/* Archive/Unarchive Account Action */}
            {isProfileListArchived === false ? (
                <div
                    role="button"
                    tabIndex={0}
                    className={`dropdown-item pl-1 cmn_btn_multiArchived ${
                        (selectedRows as any[]).length > 0 &&
                        authorization[PROFILE_ACCESS_ATHORIZATION_ID] !==
                            undefined &&
                        authorization[PROFILE_ACCESS_ATHORIZATION_ID].Modifie
                            ? ""
                            : "disabled_clz"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        handleMultiProfileArchiverAction(selectedRows);
                    }}
                >
                    <TrashIcon
                        width={35}
                        height={25}
                        fill="red"
                        style={{ cursor: "pointer" }}
                    />
                    <span className="ml-2">{t("Archive profile(s)")}</span>
                </div>
            ) : (
                <div
                    role="button"
                    tabIndex={0}
                    className={`dropdown-item pl-1 cmn_btn_unarchiveProfile ${
                        (selectedRows as any[]).length > 0 &&
                        authorization[PROFILE_ACCESS_ATHORIZATION_ID] !==
                            undefined &&
                        authorization[PROFILE_ACCESS_ATHORIZATION_ID].Modifie
                            ? ""
                            : "disabled_clz"
                    } `}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        handleProfileUnArchiveAction(selectedRows);
                    }}
                >
                    <UnArchiveIcon width={35} height={25} />
                    <span className="ml-2">{t("Unarchive profile(s)")}</span>
                </div>
            )}
        </>
    );
}

export default ActionMultipleProfile;
