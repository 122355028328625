/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { useKeycloak } from "@react-keycloak/web";

import { DynamicTable } from "@aures/custom-react-table";
import { useTranslation } from "react-i18next";
import { PencilIcon } from "@aureskonnect/react-ui";

import { useSelector } from "react-redux";
import { RootState } from "@store/index";

import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";

import { DynamicTableWrapperContext } from "@components/Common";
import {
    StatusActive,
    USER_ACCOUNT_ACCESS_ATHORIZATION_ID,
} from "@constants/index";
import ConfirmBlockModal from "@components/Common/Modals/ConfirmBlockModal";
import ConfirmArchivedModal from "@components/Common/Modals/ConfirmArchivedModal";
import { ManageAccessModal } from "@components/Common/Modals/AccessManagment/ManageAccessModal";
import HistoryModal from "@components/Common/Modals/HistoryModal";
import ConfirmDifferenceAccessModification from "@components/Common/Modals/AccessManagment/ConfirmDifferenceAccessModification";
import ActionColumn from "./ActionColumn";
import ActionCustonFilter from "./ActionCustonFilter";

import "./style.css";

export function UserAccountList(): JSX.Element {
    const { keycloak } = useKeycloak();

    const { t } = useTranslation();
    const i18nextLng = localStorage.getItem("i18nextLng") as string;
    const customerAccountId = localStorage.getItem("customerAccountId");
    const uuids = localStorage.getItem("uuids");

    const authorization = useSelector(
        (state: RootState) => state.ProfileAuthorizationSlice.data
    );

    const userAccountStatus = StatusActive;

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const isVisible = true;

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);

    const [
        isConfirmDifferenceAccessOpened,
        setIsConfirmDifferenceAccessOpened,
    ] = React.useState(false);

    const [isConfirmBlockModal, setIsConfirmBlockModal] = React.useState(false);
    const [isConfirmArchivedModal, setIsConfirmArchivedModal] =
        React.useState<boolean>(false);
    const [isHistoryModalOpened, setIsHistoryModalOpened] =
        React.useState<boolean>(false);
    const [isManageAccessModalOpened, setIsManageAccessModalOpened] =
        React.useState<boolean>(false);

    function handleOnEditUser(user: any) {
        dynamicTableWrapperContext?.setRowData({ ...user });
    }

    const handleSubmit = () => {
        dynamicTableWrapperContext?.setRowData({
            Nom: "",
            Prénom: "",
            Email: "",
            "Téléphone 1": 0,
            "Téléphone 2": 0,
            Profil: "",
            Balise: "",
            Commentaire: "",
        });

        dynamicTableWrapperContext?.setActionColumnDisplay(
            (isActionColumnDisplayed): boolean => !isActionColumnDisplayed
        );
        dynamicTableWrapperContext?.setLocalFilterActive(false);
    };

    const customJsxActions = (e: any) => (
        <div className="d-flex justify-content-around w-50">
            <div id={`Duplication${e?.selectedRow?.iuud}`}>
                <LockIconWithToolTip
                    message={t(`Duplicate`)}
                    id={`Duplication${e?.selectedRow?.iuud}`}
                />
            </div>

            <div
                id={`Modifier${e?.selectedRow?.iuud}`}
                className={`${
                    authorization[USER_ACCOUNT_ACCESS_ATHORIZATION_ID] !==
                        undefined &&
                    authorization[USER_ACCOUNT_ACCESS_ATHORIZATION_ID].Modifie
                        ? ""
                        : "disabled_clz"
                } cmn_icn_edit`}
            >
                <LockIconWithToolTip
                    message={t(`Edit`)}
                    id={`Modifier${e?.selectedRow?.iuud}`}
                />
                <PencilIcon
                    style={{ cursor: "pointer" }}
                    height={25}
                    width={25}
                    onClick={() => {
                        handleOnEditUser(e.selectedRow);
                        dynamicTableWrapperContext?.setLocalFilterActive(false);
                        dynamicTableWrapperContext?.setActionColumnDisplay(
                            true
                        );
                    }}
                />
            </div>
        </div>
    );

    function handleBlockAccountOnClickEvent(user: any) {
        setIsConfirmBlockModal(!isConfirmBlockModal);
        dynamicTableWrapperContext?.setRowData({ ...user });
    }

    function handleManageAccessOnClickEvent(selectedRow: any) {
        setIsManageAccessModalOpened(!isManageAccessModalOpened);
        dynamicTableWrapperContext?.setRowData({ ...selectedRow });
    }
    function handleHistorysOnClickEvent(selectedRow: any) {
        setIsHistoryModalOpened(!isHistoryModalOpened);
        dynamicTableWrapperContext?.setRowData({ ...selectedRow });
    }
    const arrayOfCustomColumns: any[] = [
        {
            indexOFColumn: 99,
            columnName: t("Actions"),
            customJsx: customJsxActions,
        },
    ];

    async function checkMultipleAccessData() {
        if (selectedRows !== undefined && selectedRows?.length > 0) {
            const ids = selectedRows?.map((row) => {
                return row.ID;
            });
            const response = await fetch(
                `${
                    process.env.REACT_APP_ACCESS_API_URL
                }/check-multiple-access-difference?customerAccountUUid=${customerAccountId}&userAccountIds=${ids.join()}&uuids=${uuids}`,
                {
                    headers: {
                        authorization: `Bearer ${keycloak.token}`,
                    },
                }
            );

            const { isDifferenceExist } = await response.json();
            if (isDifferenceExist) {
                setIsConfirmDifferenceAccessOpened(isDifferenceExist);
            } else {
                setIsManageAccessModalOpened(true);
            }
        }
    }

    return (
        <React.Fragment>
            <DynamicTable
                url={`${process.env.REACT_APP_ACCESS_API_URL}/users?customerAccountUUid=${customerAccountId}&userAccountStatus=${userAccountStatus}&customerLang=${i18nextLng}`}
                canSort
                name="table-user"
                canResize
                showGlobalFilter
                showFilter
                canSelect
                customSelect
                requestHeader={{ authorization: `Bearer ${keycloak.token}` }}
                setSelectedRows={setSelectedRows}
                // eslint-disable-next-line react/no-unstable-nested-components
                actionColumn={(e: any) => {
                    return (
                        <ActionColumn
                            e={e}
                            isConfirmArchivedModal={isConfirmArchivedModal}
                            // eslint-disable-next-line react/jsx-no-bind
                            handleManageAccessOnClickEvent={
                                handleManageAccessOnClickEvent
                            }
                            // eslint-disable-next-line react/jsx-no-bind
                            handleBlockAccountOnClickEvent={
                                handleBlockAccountOnClickEvent
                            }
                            setIsConfirmArchivedModal={
                                setIsConfirmArchivedModal
                            }
                            // eslint-disable-next-line react/jsx-no-bind
                            handleHistorysOnClickEvent={
                                handleHistorysOnClickEvent
                            }
                        />
                    );
                }}
                arrayOfCustomColumns={arrayOfCustomColumns}
                setDataIsUpdated={dynamicTableWrapperContext?.setDataUpdated}
                dataIsUpdated={dynamicTableWrapperContext?.dataIsUpdated}
                customJsxSideFilterButton={
                    <ActionCustonFilter
                        handleSubmit={handleSubmit}
                        selectedRows={selectedRows}
                        // eslint-disable-next-line react/jsx-no-bind
                        handleManageAccessOnClickEvent={() => {
                            checkMultipleAccessData();
                        }}
                        // eslint-disable-next-line react/jsx-no-bind
                        handleBlockAccountOnClickEvent={
                            handleBlockAccountOnClickEvent
                        }
                        setIsConfirmArchivedModal={setIsConfirmArchivedModal}
                        isConfirmArchivedModal={isConfirmArchivedModal}
                        // eslint-disable-next-line react/jsx-no-bind
                        handleHistorysOnClickEvent={handleHistorysOnClickEvent}
                    />
                }
                setData={dynamicTableWrapperContext?.setDataTable}
                minHeight={`calc(100vh - (370px + ${
                    isVisible ? "100px" : "0px"
                }))`}
                maxHeight={`calc(100vh - (370px + ${
                    isVisible ? "125px" : "0px"
                }))`}
            />
            <ConfirmBlockModal
                isOpen={isConfirmBlockModal}
                setIsOpen={setIsConfirmBlockModal}
                setSelectedRows={setSelectedRows}
            />
            <ConfirmArchivedModal
                isOpen={isConfirmArchivedModal}
                setIsOpen={setIsConfirmArchivedModal}
                setSelectedRows={setSelectedRows}
            />
            <ManageAccessModal
                isOpen={isManageAccessModalOpened}
                setIsOpen={setIsManageAccessModalOpened}
                selectedRows={selectedRows}
            />

            <ConfirmDifferenceAccessModification
                isOpen={isConfirmDifferenceAccessOpened}
                setIsOpen={setIsConfirmDifferenceAccessOpened}
                // eslint-disable-next-line react/jsx-no-bind
                handleManageAccessOnClickEvent={handleManageAccessOnClickEvent}
            />
            {isHistoryModalOpened ? (
                <HistoryModal
                    setIsModalOpened={setIsHistoryModalOpened}
                    isModalOpened={isHistoryModalOpened}
                    type={7}
                />
            ) : null}
        </React.Fragment>
    );
}
