/* eslint-disable */
import React from "react";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch } from "react-redux";

import { setProfileAuthorization } from "@store/features/ProfileAuthorizationSlice";
import { setDominantColor } from "@store/features/colorSlice";

import NProgressWrapper from "@components/Common/NProgressWrapper";

import "./Login.css";

export default function LoginWithSSO() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();
    const [loading, setLoading] = React.useState<boolean>(false);

    // eslint-disable-next-line sonarjs/cognitive-complexity
    async function handleValidSubmit(values: any) {
        const urlVerifyUserAccount: string = `${process.env.REACT_APP_CONSOLE_ADMIN_API_URL}/customerAccount`;

        await fetch(urlVerifyUserAccount, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                authorization: `Bearer ${keycloak.token}`,
            },
            body: `email=${values.email}`,
            method: "POST",
        })
            .then((result) => result.json())
            .then(async (response: any) => {
                if (!response.error) {
                    if (response.data.etataccount === "Activer") {
                        localStorage.setItem("email", response.data.email);

                        localStorage.setItem(
                            "Uid_Entitie_User",
                            response.data.uidentitie
                        );
                        localStorage.setItem(
                            "firstName",
                            response.data.firstname
                        );
                        localStorage.setItem("lastName", response.data.name);
                        localStorage.setItem(
                            "Uid_User",
                            response.data.uuiduser
                        );
                        const color =
                            response.data.maincolor !== null &&
                            response.data.maincolor !== ""
                                ? response.data.maincolor
                                : "#323333";
                        document.documentElement.style.setProperty(
                            "--dominantColor",
                            color
                        );
                        localStorage.setItem("mainColor", color);
                        dispatch(setDominantColor(color));
                        setLoading(false);
                        const getCustomerListURL = await fetch(
                            `${process.env.REACT_APP_CONSOLE_ADMIN_API_URL}/accounts/${response.data.uidentitie}/${response.data.uuiduser}`,
                            {
                                headers: {
                                    authorization: `Bearer ${keycloak.token}`,
                                },
                            }
                        );
                        fetch(
                            `${process.env.REACT_APP_ACCESS_API_URL}/used-profile-autorisation?uidUser='${response.data.uuiduser}'`,
                            {
                                method: "get",
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bearer ${keycloak.token}`,
                                },
                            }
                        ).then((response) => {
                            response.json().then((data) => {
                                dispatch(
                                    setProfileAuthorization(data.authorization)
                                );
                            });
                        });

                        const customerList = await getCustomerListURL.json();
                        let uuids: string = "";
                        for (const uid of customerList.data) {
                            uuids += `,'${uid.uid}'`;
                        }

                        localStorage.setItem(
                            "uuids",
                            `"${uuids.substring(1)}"`
                        );

                        localStorage.setItem(
                            "NumberOfAccount",
                            customerList.data.length
                        );
                        // eslint-disable-next-line sonarjs/no-all-duplicated-branches
                        if (customerList.data.length > 1) {
                            navigate("/customer-accounts");
                        } else {
                            localStorage.setItem(
                                "customerAccountId",
                                customerList.data[0].uid
                            );

                            localStorage.setItem(
                                "TypeAccount",
                                customerList.data[0][t("Account type")]
                            );

                            localStorage.setItem(
                                "customerAccountName",
                                customerList.data[0][t("Customer account")]
                            );

                            const selectedCustomerCodeUrl = await fetch(
                                `${process.env.REACT_APP_SETTING_API_URL}settings/pays`,
                                {
                                    headers: {
                                        authorization: `Bearer ${keycloak.token}`,
                                    },
                                }
                            );

                            const countryCode: any =
                                await selectedCustomerCodeUrl.json();
                            if (countryCode.data !== undefined) {
                                const selectedCountryCode =
                                    countryCode.data.find(
                                        (data: any) =>
                                            data.Designation ===
                                            customerList.data[0][t("Country")]
                                    );

                                localStorage.setItem(
                                    "selectedCountryCode",
                                    selectedCountryCode.code.toLowerCase()
                                );
                            } else {
                                localStorage.setItem(
                                    "selectedCountryCode",
                                    "fr"
                                );
                            }
                            navigate("/users-accounts");
                        }
                    } else {
                        // eslint-disable-next-line
                        if (response.data.accounttype === "ADMIN") {
                            // eslint-disable-next-line
                            console.log(
                                "Vous n'êtes plus autorisé à utiliser ce compte. Veuillez contacter notre service support."
                            );
                        } else {
                            //  eslint-disable-next-line
                            console.log(
                                "Vous n'êtes plus autorisé à utiliser ce compte. Veuillez contacter votre administrateur."
                            );
                        }
                    }
                } else {
                    console.log(
                        t("Compte introuvable. Vérifiez votre adresse e-mail.")
                    );
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(true);

                return false;
            });
    }

    React.useEffect(() => {
        const uidEntity = localStorage.getItem("Uid_Entitie_User");

        if (uidEntity !== null && uidEntity === keycloak.tokenParsed?.uuid) {
            navigate("/customer-accounts");
        } else {
            handleValidSubmit({
                // keycloak redire
                email: keycloak.authenticated && keycloak.tokenParsed?.email,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // React.useEffect(() => {

    //     handleValidSubmit({
    //         email: keycloak.authenticated && keycloak.tokenParsed?.email,
    //     });
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (
        <Container fluid={true} className="p-0 m-0 overflow-hidden">
            <NProgressWrapper isLoading={loading} />
        </Container>
    );
}
